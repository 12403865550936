import React, { Suspense, useMemo } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HistoryPhysical17A from "./modules/HistoryPhysicalModule/HistoryPhysical17A";
import HistoryPhysical22A from "./modules/HistoryPhysicalModule/HistoryPhysical22A";
import Layout from "./common/Layout";
import { routes } from "./routes/routesPath";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import { Loader } from "./packages";
import { DARK } from "./theme/colors";

const SigninScreen = React.lazy(
  () => import("./modules/SigninModule/SigninScreen")
);
const DiscourseSessionScreen = React.lazy(
  () => import("./modules/ForumModule/DiscourseSessionScreen")
);
const EnrollScreen = React.lazy(
  () => import("./modules/BecomeProviderModule/EnrollScreen")
);
const NotFoundScreen = React.lazy(() => import("./common/NotFoundScreen"));

const SettingScreen = React.lazy(
  () => import("./modules/SettingModule/SettingScreen")
);
const DeleteAccountScreen = React.lazy(
  () => import("./modules/DeleteAccountModule/DeleteAccountScreen")
);
const FindProviderViewScreen = React.lazy(
  () => import("./modules/FindProviderModule/FindProviderViewScreen")
);
const FindProviderScreen = React.lazy(
  () => import("./modules/FindProviderModule/FindProviderScreen")
);
const BecomeProviderScreen = React.lazy(
  () => import("./modules/BecomeProviderModule/BecomeProviderScreen")
);
const LandingScreen = React.lazy(
  () => import("./modules/LandingModule/LandingScreen")
);
const AboutUsScreen = React.lazy(
  () => import("./modules/AboutUsModule/AboutUsScreen")
);
const HistoryPhysicalExamScreen = React.lazy(
  () => import("./modules/HistoryPhysicalModule/HistoryPhysicalExamScreen")
);
const ProfileScreen = React.lazy(
  () => import("./modules/ProfileModule/ProfileScreen")
);
const CustomerSupportScreen = React.lazy(
  () => import("./modules/MailbagModule/CustomerSupportScreen")
);
const GPTScreen = React.lazy(() => import("./modules/GPTModule/GPTScreen"));
const AskTheDocScreen = React.lazy(
  () => import("./modules/AskTheDocModule/AskTheDocScreen")
);
const VideoScreen = React.lazy(
  () => import("./modules/VideoModule/VideoScreen")
);
const MyLabScreen = React.lazy(
  () => import("./modules/MyLabModule/MyLabScreen")
);
const ForumScreen = React.lazy(
  () => import("./modules/ForumModule/ForumScreen")
);
const ChangePasswordScreen = React.lazy(
  () => import("./modules/SigninModule/ChangePasswordScreen")
);
const TermsServiceScreen = React.lazy(
  () => import("./modules/PrivacyPolicyModule/TermsServiceScreen")
);
const PrivacyPolicyScreen = React.lazy(
  () => import("./modules/PrivacyPolicyModule/PrivacyPolicyScreen")
);
const BillingScreen = React.lazy(
  () => import("./modules/BillingModule/BillingScreen")
);
const AddDetailsScreen = React.lazy(
  () => import("./modules/ABCDsModule/AddDetailsScreen")
);
const ABCDsScreen = React.lazy(
  () => import("./modules/ABCDsModule/ABCDsScreen")
);
const TodoScreen = React.lazy(() => import("./modules/TodoModule/TodoScreen"));
// const ResponsiveDrawer = React.lazy(() => import("./common/ResponsiveDrawer"));
const SignUpScreen = React.lazy(
  () => import("./modules/SignUpModule/SignUpScreen")
);
const ForgotPasswordScreen = React.lazy(
  () => import("./modules/SigninModule/ForgotPasswordScreen")
);
const HomeScreen = React.lazy(() => import("./modules/HomeModule/HomeScreen"));
const OtpVerificationScreen = React.lazy(
  () => import("./modules/SigninModule/OtpVerificationScreen")
);
const ConnectTestoScreen = React.lazy(
  () => import("./modules/ConnectTestoModule/ConnectTestoScreen")
);

const historyPhysicalModules = Array.from({ length: 72 }, (_, i) =>
  React.lazy(
    () => import(`./modules/HistoryPhysicalModule/HistoryPhysical${i + 1}`)
  )
);

const AppProvider = () => {
  const routeList = useMemo(() => {
    return [
      {
        component: <LandingScreen />,
        path: "/",
        show: true,
      },
      {
        component: <DiscourseSessionScreen />,
        path: routes.DISCOURSE_SESSION_SSO,
        show: true,
      },
      {
        component: <SigninScreen />,
        path: routes.SIGNIN,
        show: true,
      },
      {
        component: <ForgotPasswordScreen />,
        path: routes.PASSOWRD,
        show: true,
      },
      {
        component: <OtpVerificationScreen />,
        path: routes.OTP_VERIFICATION,
        show: true,
      },
      {
        component: <SignUpScreen />,
        path: routes.SIGNUP,
        show: true,
      },
      {
        component: <PrivacyPolicyScreen />,
        path: routes.PRIVACY_POLICY,
        show: true,
      },
      {
        component: <AboutUsScreen />,
        path: routes.ABOUT_US,
        show: true,
      },
      {
        component: <TermsServiceScreen />,
        path: routes.TERMS_OF_SERVICE,
        show: true,
      },
      {
        component: <ChangePasswordScreen />,
        path: routes.CHANGE_PASSWORD,
        show: true,
      },
      {
        component: <DeleteAccountScreen isUser={false} />,
        path: routes.DELETE_ACCOUNT,
        show: true,
      },
      {
        component: <BecomeProviderScreen />,
        path: routes.BECOME_A_PROVIDER,
        show: true,
      },
      {
        component: <EnrollScreen />,
        path: routes.ENROLL,
        show: true,
      },
      {
        component: <FindProviderScreen />,
        path: routes.FIND_A_PROVIDER,
        show: true,
      },
      {
        component: <FindProviderViewScreen />,
        path: routes.FIND_A_PROVIDER_VIEW,
        show: true,
      },
    ];
  }, []);

  const authRouteList = useMemo(() => {
    return [
      {
        component: <DeleteAccountScreen isUser />,
        path: routes.DELETE_ACCOUNT_USER,
        show: true,
      },
      {
        component: <ChangePasswordScreen isProtected />,
        path: routes.CHANGE_PASSWORD_AUTH,
        show: true,
      },
      {
        component: <HomeScreen />,
        path: routes.HOME,
        show: true,
      },

      {
        component: <ConnectTestoScreen />,
        path: routes.CONNECT_YOUR_TESTO,
        show: true,
      },
      {
        component: <BillingScreen />,
        path: routes.BILLING,
        show: true,
      },

      {
        component: <ABCDsScreen />,
        path: routes.ABCDS,
        show: true,
      },
      {
        component: <AddDetailsScreen />,
        path: routes.ABCDS_ADD ,
        show: true,
      },
      {
        component: <AddDetailsScreen />,
        path: routes.ABCDS_EDIT ,
        show: true,
      },
      {
        component: <TodoScreen />,
        path: routes.TODO,
        show: true,
      },
      {
        component: <MyLabScreen />,
        path: routes.LABS,
        show: true,
      },
      {
        component: <ForumScreen />,
        path: routes.FORUM,
        show: true,
      },
      {
        component: <VideoScreen />,
        path: routes.VIDEOS,
        show: true,
      },
      {
        component: <AskTheDocScreen />,
        path: routes.ASK_THE_DOC,
        show: true,
      },
      {
        component: <GPTScreen />,
        path: routes.DRAI,
        show: true,
      },
      {
        component: <CustomerSupportScreen />,
        path: routes.SUPPORT,
        show: true,
      },
      {
        component: <ProfileScreen />,
        path: routes.PROFILE,
        show: true,
      },
      {
        component: <SettingScreen />,
        path: routes.SETTINGS,
        show: true,
      },
      {
        component: <HistoryPhysicalExamScreen />,
        path: routes.HISTORY_PHYSICAL,
        show: true,
      },
      {
        component: <HistoryPhysical17A />,
        path: routes.HISTORY_PHYSICAL_17A,
        show: true,
      },
      {
        component: <HistoryPhysical22A />,
        path: routes.HISTORY_PHYSICAL_22A,
        show: true,
      },
    ];
  }, []);

  return (
    <Suspense fallback={<Loader backgroundColor={DARK} />}>
      <Router basename="/">
        <Routes>
          <Route element={<Layout />}>
            {routeList.map(
              (item) =>
                item.show && (
                  <Route
                    key={item.path}
                    path={item.path}
                    element={item.component}
                  />
                )
            )}
          </Route>

          <Route element={<Layout isNav />}>
            <Route element={<ProtectedRoutes />}>
              {authRouteList.map(
                (item) =>
                  item.show && (
                    <Route
                      key={item.path}
                      path={item.path}
                      element={item.component}
                    />
                  )
              )}

              {historyPhysicalModules.map((Component, i) => (
                <Route
                  key={i}
                  path={`/history-physical-${i + 1}`}
                  element={<Component />}
                />
              ))}
            </Route>
          </Route>
          <Route path="*" element={<NotFoundScreen />} />
        </Routes>
      </Router>
    </Suspense>
  );
};

export default AppProvider;
